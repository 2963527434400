export const hasWindow = typeof window !== 'undefined' && window

export const isUnsupported =
  hasWindow &&
  navigator !== undefined &&
  (/MSIE 9/i.test(navigator.userAgent) ||
    /MSIE 10/i.test(navigator.userAgent) ||
    /Trident/i.test(navigator.userAgent))

export const isLocalDev = hasWindow && process.env.NODE_ENV === 'development'

/**
 * URI encode object key:value pairs
 *
 * @param {Object} data
 */
export const encodeParams = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

/**
 * Detect if site is running in an IFrame; if so, returns true
 */
export const inIFrame = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    // raised by browsers if iframe is cross-domain
    return true
  }
}

/**
 * Update URLs that point to media on the original MPAtlas without HTTPS prefix
 * @param {*} html
 */
export const updateURLs = (html) =>
  html.replace(
    /http:\/\/www.mpatlas.org\/media\//gi,
    'http://old.mpatlas.org/media/'
  )

export const scrollIntoView = (elementId) => {
  if (!hasWindow) {
    return
  }

  const element = window.document.getElementById(elementId)
  if (!element) {
    return
  }

  try {
    element.scrollIntoView({
      behavior: 'smooth',
    })
  } catch (ex) {
    console.error(
      'scrollIntoView with options does not appear to be supported',
      ex
    )
    try {
      element.scrollIntoView()
    } catch (ex2) {
      console.error('scrollIntoView does not appear to be supported', ex2)
    }
  }
}

export const scrollToTop = (elementId = null) => {
  if (!hasWindow) {
    return
  }

  if (elementId) {
    const element = window.document.getElementById(elementId)
    if (!element) {
      return
    }
    try {
      element.scrollTo(0, 0)
    } catch (ex) {
      console.error(`scrollToTop failed for ${elementId}`)
    }
  } else {
    window.scrollTo(0, 0)
  }
}

/**
 * Encode object to URL-encoded query parameter string.
 * Arrays are encoded to A<value1>.<value2>.<value3>
 * Sets are encoded to S<value1>.<value2>.<value3>
 * @param {Object} newState
 * @returns
 */
export const encodeQueryParams = (obj) => {
  const searchParams = new URLSearchParams('')

  Object.entries(obj).forEach(([key, value]) => {
    let encodedValue = value !== null ? value.toString() || null : null

    // if value is an array, encode values
    if (value instanceof Array) {
      encodedValue = value.length > 0 ? `A${value.sort().join('.')}` : null
    }

    // if value is a set, encode values
    if (value instanceof Set) {
      encodedValue =
        value.size > 0 ? `S${[...value.values()].sort().join('.')}` : null
    }

    if (encodedValue !== null) {
      searchParams.append(key, encodedValue)
    }
  })

  return searchParams.toString()
}

/**
 * Decodes window.location.href into object, reversing encoding above.
 * @returns Object
 */
export const decodeQueryParams = () => {
  const entries = [
    ...new window.URLSearchParams(window.location.search).entries(),
  ]

  /* eslint-disable-next-line no-restricted-globals */
  const decodeValue = (v) => (isNaN(v) ? v : parseInt(v, 10))

  const out = {}
  entries.forEach(([key, value]) => {
    let decodedValue = value

    // Array
    if (value.startsWith('A')) {
      decodedValue = value.slice(1, value.length).split('.').map(decodeValue)
    }

    // Set
    if (value.startsWith('S')) {
      decodedValue = new Set(
        value.slice(1, value.length).split('.').map(decodeValue)
      )
    }

    out[key] = decodedValue
  })

  return out
}
