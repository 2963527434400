const { lighten, darken } = require('@theme-ui/color')

module.exports = {
  useColorSchemeMediaQuery: false,
  useLocalStorage: false,
  breakpoints: ['600px', '800px', '1200px', '1600px'],
  colors: {
    // https://colors.eva.design/ based on #32acd1
    text: '#333',
    background: '#fff',
    primary: '#32acd1', // existing primary is #27a7cf
    accent: '#FCAF2A',
    error: '#FF4032',
    ok: '#69B51E',
    filtered: '#ee7a14',
    // based on palx for #27a7cf
    cyan: {
      0: '#f4fbfd',
      1: '#dcf1f7',
      2: '#c3e7f2',
      3: '#a6dbeb',
      4: '#86cee4',
      5: '#60bfdc',
      6: '#32acd1', // primary
      7: '#2294b7',
      8: '#1b7590',
      9: '#104555',
    },
    grey: {
      0: '#f8fafa',
      1: '#ebeeef',
      2: '#dce2e3',
      3: '#ccd4d7',
      4: '#bbc6c9',
      5: '#a8b5b9',
      6: '#92a3a8',
      7: '#778d93',
      8: '#5c6f75',
      9: '#364145',
      10: '#001626', // existing dark
    },
  },
  fonts: {
    body: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    heading: 'Georgia, serif',
    // NOTE: logo font is museo
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 72, 112],
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  layout: {
    container: {
      maxWidth: '960px',
    },
    sidebar: {
      // middle stops? '320px', '360px',
      width: ['100%', '468px'],
      borderRightWidth: ['0px', '1px'],
      borderRightColor: 'grey.8',
    },
  },
  text: {
    default: {
      display: 'block', // fix for theme-ui v6 (div => span)
    },
    help: {
      display: 'block',
      color: 'grey.7',
      fontSize: 1,
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      bar: {
        textAlign: 'center',
        p: '1rem',
        mb: '2rem',
        bg: 'grey.1',
        borderBottom: '1px solid',
        borderTop: '1px solid',
        borderBottomColor: 'grey.3',
        borderTopColor: 'grey.3',
      },
      block: {
        fontSize: [3, 4],
        pb: '0.5rem',
      },
    },
    blockHeading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [3, 4],
      pb: '0.5rem',
    },
    field: {
      display: 'block',
      wordBreak: 'break-word',
      overflow: 'hidden',
      '& >span:first-of-type': {
        fontWeight: 'bold',
        fontSize: 3,
        borderBottom: '1px solid',
        borderBottomColor: 'grey.2',
        mb: '0.25rem',
        pb: '0.25rem',
      },
      '& > span + span': {
        ml: '1rem',
      },
      '&:not(:first-of-type)': {
        mt: '1.5rem',
      },
    },
  },
  lineHeights: {
    body: 1.4,
    heading: 1.2,
  },
  alerts: {
    error: {
      color: '#FFF',
      bg: 'error',
    },
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      '&:hover': {
        bg: darken('primary', 0.1),
      },
    },
    secondary: {
      cursor: 'pointer',
      color: 'grey.9',
      bg: 'grey.1',
    },
    accent: {
      cursor: 'pointer',
      color: '#FFF',
      bg: 'accent',
    },
    close: {
      cursor: 'pointer',
      outline: 'none',
      background: 'none',
      color: 'grey.5',
      '&:hover': { color: 'grey.9' },
    },
    sort: {
      py: 0,
      px: '0.5em',
      bg: 'transparent',
      color: 'grey.6',
      cursor: 'pointer',
      '&:hover': {
        color: 'grey.8',
      },
      outline: 'none',
    },
    sortActive: {
      py: 0,
      px: '0.5em',
      bg: 'transparent',
      cursor: 'pointer',
      color: 'accent',
      outline: 'none',
      fontWeight: 'bold',
    },
  },
  grids: {
    thirds: {
      gap: 3,
    },
    half: {
      gap: 5,
    },
    'table-row': {
      pt: '0.5em',
      lineHeight: 1.2,
      '& > div + div': {
        textAlign: 'center',
        px: '0.5em',
      },
    },
    'table-header': {
      pt: '0.5em',
      lineHeight: 1.2,
      fontWeight: 'bold',
      borderBottom: '1px solid',
      borderBottomColor: 'grey.2',
      '& > div + div': {
        textAlign: 'center',
        px: '0.5em',
      },
    },
  },

  boxes: {
    section: {
      mt: '6rem',
      pt: '3rem',
      borderTop: '0.5rem solid',
      borderTopColor: 'grey.9',
    },
  },
  styles: {
    root: {
      bg: 'grey.0',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
      margin: 0,
      body: {
        margin: 0,
        height: '100%',
        width: '100%',
      },
      '#___gatsby': {
        height: '100%',
      },
      '#___gatsby > *': {
        height: '100%',
      },
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      p: {
        fontSize: [2, 2, 3],
      },
      h1: {
        variant: 'text.heading',
        fontSize: [5, 5, 6],
      },
      h2: {
        variant: 'text.heading',
        fontSize: [4, 4, 5],
      },
      h3: {
        variant: 'text.heading',
        fontSize: [3, 3, 4],
      },
      h4: {
        variant: 'text.heading',
        fontSize: [2, 2, 3],
      },
      a: {
        color: 'primary',
        textDecoration: 'none',
      },
      'a:hover': {
        textDecoration: 'underline',
      },
      ul: {
        margin: 0,
        pl: '1.25em',
        'li + li': {
          mt: '0.25em',
        },
      },
      dl: {
        dt: {
          fontWeight: 'bold',
          fontSize: 3,
          '&:not(:first-of-type)': {
            mt: '1rem',
          },
        },
      },
    },
    hr: {
      color: 'grey.2',
      my: '2rem',
      space: {
        color: 'grey.2',
        my: '4rem',
      },
      wide: {
        height: '0.5rem',
        bg: 'grey.9',
        borderBottom: 'none',
      },
    },
    progress: {
      color: 'primary',
      bg: 'grey.2',
      height: '1rem',
      percent: {
        height: '0.5rem',
        borderRadius: 0,
        color: 'primary',
        bg: 'grey.2',
      },
      stackedPercent: {
        height: '0.5rem',
        bg: 'grey.2',
        color: 'primary',
        // colorFunc gets a lighter or darker color for each bar
        // as a function of its position in the list of bars.
        colorFunc: (i, count) =>
          lighten('primary', (0.5 * (count - 1 - i)) / count),
      },
      relative: {
        height: '0.5rem',
        borderRadius: 0,
        color: 'primary',
        bg: 'transparent',
      },
    },
  },
}
